<template>
    <section>
        <section class="bg-home-hero-two 2xl:bg-cover bg-cover 2xl:bg-center bg-no-repeat pb-14 lg:pb-[120px]">
            <div class="flex flex-col 3xl:pb-[22px]">
                <HomeHeader
                    layout="client"
                    :primary-menu-links="primaryMenuLinks"
                    :sidebar-menu-links="sidebarMenuLinks">
                    <!-- <InertiaLink :href="route('vendor')" class="flex items-center mt-6 hover:text-primary">
                        <span class="text-[28px] leading-10">{{ $t('Looking to provide your own talents?') }}</span>
                        <ArrowUpRightIcon class="size-12" />
                    </InertiaLink>
                    <InertiaLink
                        :href="route('login')"
                        class="w-fit text-base text-base-content font-medium uppercase rounded-full px-6 py-4 bg-[#F1F6FF]">
                        <span>{{ $t('Login as vendor') }} </span>
                    </InertiaLink> -->
                </HomeHeader>
                <section
                    class="flex flex-col gap-y-8 mt-[70px] md:mt-[78px] lg:mt-[104px] 2xl:mt-[144px] mx-6 md:mx-14 2xl:mx-40 3xl:mx-[270px]">
                    <span class="w-fit font-medium text-sm inline-block text-s-gradient uppercase">{{
                        isHomePage ? $t('Grow your business and MANAGE your team IN ONE PLACE') : $t('SCALING PLATFORM')
                    }}</span>
                    <span
                        class="text-4xl lg:text-[52px] 2xl:text-[78px] leading-[43px] lg:leading-[66px] 2xl:leading-[95px] lg:font-light tracking-tight w-3/4">
                        {{ title }}
                    </span>
                    <span class="text-xl lg:leading-8 max-w-[938px]">
                        {{ description }}
                    </span>
                    <a
                        v-if="isHomePage"
                        href="/#how-it-works"
                        class="w-full md:w-fit h-12 rounded-full bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 p-[1px] hover:bg-none cursor-pointer">
                        <div
                            class="flex justify-center items-center text-center hover:btn-sp-100 bg-primary-content rounded-full h-full group">
                            <div class="flex gap-4 items-center md:mx-6">
                                <span
                                    class="text-lg font-medium 2xl:text-xl 2xl:font-semibold bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 inline-block text-transparent bg-clip-text group-hover:text-white">
                                    {{ $t('I’m ready to scale!') }}
                                </span>
                                <span
                                    class="text-base bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 text-transparent bg-clip-text group-hover:text-white">
                                    {{ $t('Discover talents') }}
                                </span>
                            </div>
                        </div>
                    </a>
                </section>
            </div>
        </section>
        <div
            class="relative w-full h-[328px] lg:h-80 overflow-hidden"
            :class="{
                ' lg:h-[800px]': isHomePage,
            }">
            <video
                class="absolute h-full w-full object-cover object-center"
                autoplay
                muted
                loop
                disablePictureInPicture
                playsinline>
                <source src="/videos/team_meeting.mp4" type="video/mp4" />
            </video>
            <div class="absolute inset-0 bg-[#0E1924] opacity-75" />
            <div
                class="hidden absolute -right-64 xl:-right-32 top-1/4 lg:top-40 h-[447px] w-96 lg:block xl:w-96"
                :class="{'bg-dot-white': isHomePage}" />
            <div
                v-if="isHomePage"
                class="flex flex-col gap-6 lg:gap-11 absolute left-1/2 top-4 lg:top-1/3 -translate-x-1/2 text-white m-auto text-2xl lg:text-[32px] w-11/12 lg:w-2/3 xl:w-11/12 2xl:w-10/12 3xl:w-9/12">
                <span class="uppercase text-sm m-auto text-center">{{
                    $t('The right professionals and clients around the world')
                }}</span>

                <InertiaLink
                    :href="route('projects.create')"
                    class="flex gap-0 p-8 bg-white/10 w-fit max-w-[664px] m-auto rounded-2xl border-l-4 cursor-pointer hover:bg-white hover:text-base-content">
                    <div class="flex flex-col w-fit">
                        <span class="text-xl lg:text-[78px] leading-[95px] w-fit">{{ $t('Find talents') }}</span>
                        <span class="text-sm lg:text-lg w-11/12">{{
                            $t(
                                'I want to scale my team and find the right professional for my project based on my needs.',
                            )
                        }}</span>
                    </div>
                    <ArrowUpRightIcon class="size-10 lg:size-20" />
                </InertiaLink>
            </div>
            <div
                v-else
                class="flex absolute left-1/2 top-1/4 md:top-1/3 -translate-x-1/2 text-white m-auto text-2xl lg:text-[32px] w-11/12 lg:w-2/3 xl:w-11/12 2xl:w-10/12 3xl:w-9/12 before:content-quote-icon before:w-7 before:inline-block">
                <div class="absolute my-0 mx-auto p-0 border h-4/5 top-10 left-3"></div>
                <div class="ml-6">
                    {{
                        $t(
                            'Our powerful yet easy-to-use system frees up your time to focus on what truly matters: business success.',
                        )
                    }}
                </div>
            </div>
        </div>

        <div class="bg-primary-content">
            <slot></slot>
        </div>
        <div
            class="flex flex-col items-center xl:flex-row gap-8 lg:gap-12 xl:gap-24 bg-scaling bg-cover bg-center bg-no-repeat py-14 lg:py-[72px] xl:py-[104px] px-6 lg:px-14 2xl:px-[270px] text-white">
            <div class="text-2xl font-medium text-center md:text-left lg:text-[44px] xl:text-[32px] leading-10">
                {{ $t('Are you ready for a strategic scaling?') }}
            </div>
            <div class="flex flex-col items-center xl:items-start gap-6">
                <InertiaLink
                    :href="route('projects.create')"
                    class="w-fit border rounded-full border-white py-4 px-6 text-xl font-semibold cursor-pointer bg-transparent text-white text-center hover:bg-white hover:text-base-content">
                    {{ $t('Yes! I’m ready to scale my team') }}
                </InertiaLink>
                <span class="uppercase text-sm text-center xl:text-left leading-6">{{
                    $t(
                        'Not sure what to request? No problem! Our tech team is ready to assist you to make the right strategic choice based on your goals',
                    )
                }}</span>
            </div>
        </div>
        <HomeFooter />
    </section>
</template>
<script setup lang="ts">
import HomeFooter from './Partials/Frontend/HomeFooter.vue';
import HomeHeader from './Partials/Frontend/HomeHeader.vue';
import {Link as InertiaLink} from '@inertiajs/vue3';
import {ArrowUpRightIcon} from '@heroicons/vue/24/outline';
import {trans} from 'laravel-vue-i18n';

defineProps({
    title: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: true,
    },
    isHomePage: {
        type: Boolean,
        default: false,
    },
});

const primaryMenuLinks = [
    {
        href: '/#advantages',
        label: trans('Advantages'),
    },
    {
        href: '/#how-it-works',
        label: trans('How it works'),
    },
    {
        href: 'projects.create',
        label: trans('Scale now'),
        button: true,
    },
];

const sidebarMenuLinks = [
    {
        href: '/',
        label: trans('Home'),
    },
    {
        href: '/#technologies',
        label: trans('Technologies'),
    },
    {
        href: '/#advantages',
        label: trans('Advantages'),
    },
    {
        href: '/#how-it-works',
        label: trans('How it works'),
    },
    {
        href: 'https://jagaad.com',
        label: trans('Discover Jagaad'),
        external: true,
    },
];
</script>
